$white: #fff;
$black: #000;
$red: #f51826;
$dark-grey: #1c1a1a;
// $light-grey: #515151;
$background: #f4f4f4;
$grey-input: #333132;
$red-opacity: #f518277a;
$black-opacity50:rgba(0, 0, 0, 0.5);
$black-opacity90:rgba(0, 0, 0, 0.9);
$light-grey: #9A9A9A;

$font: 'Montserrat Alternates', sans-serif;
$font2: 'Montserrat', sans-serif;