// MEDIA QUERIES

/*
$breakpoints
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content };   // 600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content };  // 900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content };     // 1200px
    }
    // desktop regular between those two           // 1200-1600 px
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) { @content };  // 1800px+
    }
}

@mixin transition($duration) {
    -webkit-transition: all $duration ease;
    -moz-transition: all $duration ease;
    -o-transition: all $duration ease;
    transition: all $duration ease;
}