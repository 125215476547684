.company-bg {
    background: url("/img/image-3.jpg") center no-repeat;
    background-size: cover;
    margin: 0;

    .row {
        display: flex;

        .left {
            width: calc(60% - 2rem);
            margin-right: 2rem;

            @include respond(phone) {
                width: 100%;
            }
        }

        .right {
            width: 40%;

            @include respond(phone) {
                width: 100%;
            }
        }
    }

    .company-text {
        color: $white;
        padding: 15rem 0 5.5rem 0;
        width: 100%;
        max-width: 68rem;

        @include respond(phone) {
            margin: 0 2rem;
        }


        .company-header {
            font-size: 5.5rem;
            line-height: normal;
            font-weight: 800;
        }

        .company-p {
            font-size: 1.8rem;
            font-weight: 500;
            letter-spacing: 0.1rem;
            padding: 5rem 0;
            margin-bottom: 0;
            max-width: 57rem;

            @include respond(phone) {
                margin-bottom: 2rem;
            }
        }

        .company-showreel {
            display: flex;
            align-items: center;
            margin-bottom: 15.5rem;

            p {
                font-size: 1.4rem;
                letter-spacing: 0.1rem;
                margin-bottom: 0;

            }

            .showreel {
                margin: 0 2rem;
            }
        }

        .company-social {
            display: flex;
            align-items: center;

            @include respond(phone) {
                justify-content: flex-start;
            }

            p {
                font-family: $font2;
                margin-bottom: 0;
                font-weight: bold;
                font-size: 1.8rem;

                @include respond(phone) {
                    width: 13rem;
                }
            }

            .company-social-items {
                display: flex;
                align-items: stretch;
                a {
                    width: 1.6rem;
                    margin: 0.5rem;

                    img {
                        width: 100%;
                        height: auto;

                    }
                }
            }

        }
    }
}
hr {
    height: 0.1rem;
    width: 8rem;
    margin: 0 2.5rem;
}

.accent {
    color: $red;

    @include transition(.3s);

    &:hover {
        color: $dark-grey;
    }
}

h2 {
    font-size: 4.2rem;
    margin-bottom: 5rem;

    @include respond(phone) {
        text-align: center;
        font-size: 3.5rem;
    }
}

h2::before {
    content: url("/img/red.svg");
    height: 2rem;
    padding-right: 2rem;

    @include respond(phone) {
        padding-right: 1rem;
    }
}

.services {
    margin: 10.7rem 0 13.4rem;

    .services-items {
        display: flex;
        gap: 2rem;

        @include respond(phone) {
            flex-direction: column;
            align-items: center;
        }

        .services-item {
            p {
                font-size: 1.8rem;
                font-weight: 500;
                margin-bottom: 3rem;
            }
            .services-item-img {
                width: 100%;
                max-width: 26.5rem;
            }
            img {
                width: 100%;
                height: auto;
                border-radius: 1rem;
            }
        }
    }
}
.about-us {

    .row {
        display: flex;
        align-items: center;
    }
    .left {
        width: 40%;
        position: relative;

        @include respond(phone) {
            display: none;
        }

        .about-us-img1 {
            padding-left: 1.3rem;
            width: 110%;
            height: auto;
            position: relative;
        }
        .about-us-img2 {
            position: absolute;
            top: 0rem;
            right: 7rem;
        }
        .about-us-img3 {
            position: absolute;
            bottom: 1.5rem;
            right: 5rem;
        }
    }

    .right {
        width: 60%;
        background-color: #1c1a1b;

        @include respond(phone) {
            width: 100%;
        }

        .about-us-bg {
            background: url("/img/grey-arr-bg.svg") center no-repeat;
            background-size: 86%;
            background-position-x: 17rem;

            .about-us-text {
                color: $white;
                font-size: 1.8rem;
                padding: 8rem 0rem 13rem 15rem;
                max-width: 70rem;

                @include respond(phone) {
                    padding: 5rem 3rem;
                }

                p {
                    margin-bottom: 3rem;
                    line-height: 3rem;
                }
            }
        }
    }
}

.videos-bg {
    background: linear-gradient( $black-opacity50, $black-opacity50 ), url("/img/vid1.png") center no-repeat;
    background-size: cover;

    .videos-header {
        padding-top: 5rem;
        color: $white;
    }

    .videos-carousel {

        .focused-slide-heading {
            position: relative;
        }

        .center {
            position: absolute;
            margin: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .carousel-vid-header {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            height: 7rem;
            background-color: $black-opacity90;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: 0;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;

            p {
                font-size: 3rem;
                font-weight: bold;
                color: $white;
                padding-left: 3.8rem;
                margin-bottom: 0;
            }
        }
    }
}

.hosts {
    padding: 12rem 0 10rem;

    @include respond(phone) {
        padding: 5rem 0;
    }

    .row {
        display: flex;
        align-items: center;
        background: url("/img/white-arr-bg.svg") center no-repeat;
        background-size: 50% 55%;
        background-position-x: -10rem;

        @include respond(phone) {
            flex-direction: column;
            gap: 1rem;
        }

        .left {
            width: 40%;

            @include respond(phone) {
                width: 100%;
            }

            h2 {
                margin-bottom: 0;

                @include respond(phone) {
                    margin-bottom: 1rem;
                }
            }
        }

        .right {
            width: 60%;

            @include respond(phone) {
                width: 100%;
            }

            .hosts-items {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @include respond(phone) {
                    flex-direction: column;
                    align-items: center;
                }

                .hosts-item {
                    width: 33%;
                    max-width: 20.8rem;

                    @include respond(phone) {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    p {
                        margin-top: 2.5rem;
                        font-size: 1.8rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.client-feedbacks {
    background-color: $dark-grey;
    padding: 6rem 0;

    .client-feedbacks-header{
        color: white;
        padding-bottom: 4rem;

        @include respond(phone) {
            padding: 3rem 3rem 4rem;
        }

        h2 {
            @include respond(phone) {
                line-height: 3rem;
            }
        }
    }

    .client-feedbacks-bg {
        background: url("/img/grey-arr-bg.svg") right no-repeat;

        .feedback-item {
            background-color: white;
            width: 36rem;
            padding: 18.6rem 4rem 2.8rem;
            border-radius: 1rem;

            .feedback-item-review {
                font-size: 1.6rem;
                margin-bottom: 6.2rem;
                line-height: 2.2rem;
                text-indent: 0;
                position: relative;
            }

            .feedback-item-review::before {
                content: url("/img/quote.png");
                position: absolute;
                top: -10rem;
            }

            hr {
                height: 0.1rem;
                width: 80%;
                color: $light-grey;
                opacity: 50%;
            }

            .feedback-item-author {
                display: flex;
                gap: 2rem;
                padding: 3rem 0rem 0rem 0rem;

                .author-photo-wrapper {
                    width: 30%;
                    max-width: 6.4rem;
                }

                img {
                    border-radius: 50%;
                    width: 100%;
                    height: auto;
                }

                .feedback-author-description {
                    p {
                        margin-bottom: 0;
                    }
                    .feedback-author-name {
                        font-size: 2.2rem;
                        font-weight: 500;
                        letter-spacing: 0.1rem;
                        padding-bottom: 0.7rem;
                    }
                }
            }
        }
    }
}

.clients {
    background-color: $white;
    padding: 6.6rem 0 9.5rem;

    .clients-items {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond(phone) {
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }
    }

    .clients-item {
        width: 25%;
        max-width: 21.7rem;

        @include respond(phone) {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.blog {

    .blog-bg {
        background: url("/img/blog-bg.svg") center no-repeat;
        background-position-y: 4.1rem;
        padding-top: 13rem;

        @include respond(phone) {
            padding-top: 1rem;
        }

    .blog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond(phone) {
            flex-direction: column;
            margin-bottom: 2rem;
        }

        h2 {
            margin-bottom: 0;
            padding: 5rem 0;
        }
    }

    .blog-posts {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem 12rem;

        @include respond(phone) {
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            padding-bottom: 2rem;
        }

        .blog-post {
            width: 32%;
            position: relative;

            @include respond(phone) {
                width: 100%;
            }

            a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            img {
                width: 100%;
                height: auto;
                border-radius: 1rem;
            }

            h3 {
                margin-top: 1rem;
                font-size: 1.8rem;
                padding: 1rem 0;

                @include transition(.3s);
            }

            &:hover {
                h3 {
                    color: $red;
                }
            }

            p {
                line-height: normal;
                letter-spacing: 0.05rem;
            }

            .blog-post-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        color: $red;
                    }
                }
                p {
                    color: $light-grey;
                    margin-bottom: 0;
                }
            }
        }
    }
}
}

.contacts {
    background-color: $dark-grey;
    color: $white;

    @include respond(phone) {
        padding: 3rem 2rem 4rem;
    }

    .contacts-bg {
        background: url("/img/form-bg.svg") center no-repeat;
        background-position-x: 4rem;

        .row {
            display: flex;
            align-items: center;

            @include respond(phone) {
                flex-direction: column;
                align-items: center;
            }

            .left {
                width: calc(60% - 2rem);
                margin-right: 2rem;

                @include respond(phone) {
                    width: 100%;
                    margin-right: 0;
                }

                h2 {
                    @include respond(phone) {
                        line-height: 3rem;
                    }
                }

                p {
                    font-size: 1.8rem;
                    font-weight: 500;
                    letter-spacing: 0.1rem;
                    max-width: 60rem;

                    @include respond(phone) {
                        text-align: center;
                    }
                }
            }

            .right {
                width: 40%;

                @include respond(phone) {
                    width: 100%;
                }
            }
        }
    }
}

.contacts-form {
    margin: 16.6rem 0 16.5rem;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
        margin: 0;
    }
}
