footer {
    background: $white;

    .row {
        height: 10.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond(phone) {
            padding: 0 2rem;
        }

        .logo {
            width: 25%;
            max-width: 21.7rem;

            // это трюк для адаптивности, ширина картинки 100%, высота - авто, а ширину в единицах задаешь контейнеру выше 0 21.7rem
            img {
                width: 100%;
                height: auto;
            }
        }

        .menu {
            width: 38%;
            display: flex;
            justify-content: space-between;

            @include respond(phone) {
                display: none;
            }

            li {
                font-size: 2rem;

                a {
                    text-decoration: none;
                    color: $dark-grey;

                    // mixin для плавного перехода цвета. По опыту - 0,3 секунды - самый приятный интервал
                    @include transition(.3s);

                    &:hover {
                        cursor: pointer;
                        color: $red;
                    }
                }
            }
        }

        .info {
            width: 30%;
            max-width: 30rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                width: 70%;
            }

            .phone {
                width: 60%;

                a {
                    text-decoration: none;
                    font-weight: 700;
                    color: $red;
                    font-size: 1.6rem;
                    @include transition(.3s);

                    &:hover {
                        cursor: pointer;
                        color: $dark-grey;
                    }
                }
            }

            .social {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 40%;

                @include respond(phone) {
                    width: 30%;
                }

                a {
                    width: 2.4rem;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}