.btn {
    font-size: 1.4rem;
    background: $red;
    border: none;
    border-radius: 1rem;
    padding: 1rem 5rem;
    color: $white;
    font-family: $font;
    letter-spacing: 0.2rem;

    @include respond(phone) {
        font-size: 1.6rem;
    }
}
.circle,
.glide__arrow {
    background-color: $red;
    border-radius: 50%;
    border: none;
    color: $white;
    font-size: 1.5rem;
    cursor: pointer;

    @include respond(phone) {
        font-size: 1.6rem;
    }
}

.circle {
    width: 5rem;
    height: 5rem;
}

.glide__arrow {
    width: 4.3rem;
    height: 4.3rem;
    
    &--left {
        margin-right: 1rem;

        @include respond(phone) {
            margin-right: 0.5rem;
        }
    }
    
    &--right {
        margin-left: 1rem;

        @include respond(phone) {
            margin-left: 0.5rem;
        }
    }
  }

