*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
}
:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}
a {
    text-decoration: none;
}
ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
}
p {
    line-height: 2.6rem;
    margin-bottom: 1rem;
}
@media (max-width: 75em) {
    html {
        font-size: 56.25%;
    }
}
@media (max-width: 56.25em) {
    html {
        font-size: 50%;
    }
}
@media (max-width: 37.5em) {
    html {
        font-size: 50%;
    }
}
body {
    background: $background;
    font-family: $font;
    color: $dark-grey;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
    box-sizing: border-box; // важная штука, чтоб паддинги правильно работали

    @include respond(phone) {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}
label {
    font-weight: 700;
}
input {
    padding: .5rem;
    line-height: 3rem;
}

.wrapper {
    width: 100%;
    max-width: 112rem;
    margin: 0 auto;
}