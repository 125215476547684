/*
$breakpoints
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

a {
  text-decoration: none;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  line-height: 2.6rem;
  margin-bottom: 1rem;
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}
body {
  background: #f4f4f4;
  font-family: "Montserrat Alternates", sans-serif;
  color: #1c1a1a;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  box-sizing: border-box;
}
@media (max-width: 37.5em) {
  body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

label {
  font-weight: 700;
}

input {
  padding: 0.5rem;
  line-height: 3rem;
}

.wrapper {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
}

.btn {
  font-size: 1.4rem;
  background: #f51826;
  border: none;
  border-radius: 1rem;
  padding: 1rem 5rem;
  color: #fff;
  font-family: "Montserrat Alternates", sans-serif;
  letter-spacing: 0.2rem;
}
@media (max-width: 37.5em) {
  .btn {
    font-size: 1.6rem;
  }
}

.circle,
.glide__arrow {
  background-color: #f51826;
  border-radius: 50%;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}
@media (max-width: 37.5em) {
  .circle,
.glide__arrow {
    font-size: 1.6rem;
  }
}

.circle {
  width: 5rem;
  height: 5rem;
}

.glide__arrow {
  width: 4.3rem;
  height: 4.3rem;
}
.glide__arrow--left {
  margin-right: 1rem;
}
@media (max-width: 37.5em) {
  .glide__arrow--left {
    margin-right: 0.5rem;
  }
}
.glide__arrow--right {
  margin-left: 1rem;
}
@media (max-width: 37.5em) {
  .glide__arrow--right {
    margin-left: 0.5rem;
  }
}

.contacts-form-input {
  margin: 1rem 0;
  background-color: #333132;
  color: #fff;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 1.8rem;
  border: 1px solid #fff;
  border-radius: 1rem;
  padding: 1rem;
}
.contacts-form-input::placeholder {
  color: #fff;
}
@media (max-width: 37.5em) {
  .contacts-form-input {
    font-size: 1.6rem;
  }
}

.about {
  height: 15rem;
}

.contacts-form-textarea {
  margin: 1rem 0;
  background-color: #333132;
  color: #fff;
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 1.8rem;
  border: 1px solid #fff;
  border-radius: 1rem;
  padding: 1rem;
  height: 15rem;
}
.contacts-form-textarea::placeholder {
  color: #fff;
}
@media (max-width: 37.5em) {
  .contacts-form-textarea {
    font-size: 1.6rem;
  }
}

.glide__slide img {
  width: 100%;
  height: auto;
}

footer {
  background: #fff;
}
footer .row {
  height: 10.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  footer .row {
    padding: 0 2rem;
  }
}
footer .row .logo {
  width: 25%;
  max-width: 21.7rem;
}
footer .row .logo img {
  width: 100%;
  height: auto;
}
footer .row .menu {
  width: 38%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  footer .row .menu {
    display: none;
  }
}
footer .row .menu li {
  font-size: 2rem;
}
footer .row .menu li a {
  text-decoration: none;
  color: #1c1a1a;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
footer .row .menu li a:hover {
  cursor: pointer;
  color: #f51826;
}
footer .row .info {
  width: 30%;
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  footer .row .info {
    width: 70%;
  }
}
footer .row .info .phone {
  width: 60%;
}
footer .row .info .phone a {
  text-decoration: none;
  font-weight: 700;
  color: #f51826;
  font-size: 1.6rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
footer .row .info .phone a:hover {
  cursor: pointer;
  color: #1c1a1a;
}
footer .row .info .social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
@media (max-width: 37.5em) {
  footer .row .info .social {
    width: 30%;
  }
}
footer .row .info .social a {
  width: 2.4rem;
}
footer .row .info .social a img {
  width: 100%;
  height: auto;
}

header {
  background: #fff;
}
header .row {
  height: 10.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  header .row {
    padding: 0 2rem;
  }
}
header .row .logo {
  width: 25%;
  max-width: 21.7rem;
}
header .row .logo img {
  width: 100%;
  height: auto;
}
header .row .menu {
  width: 38%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  header .row .menu {
    display: none;
  }
}
header .row .menu li {
  font-size: 2rem;
}
header .row .menu li a {
  text-decoration: none;
  color: #1c1a1a;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header .row .menu li a:hover {
  cursor: pointer;
  color: #f51826;
}
header .row .info {
  width: 30%;
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  header .row .info {
    display: none;
  }
}
header .row .info .phone {
  width: 60%;
}
header .row .info .phone a {
  text-decoration: none;
  font-weight: 700;
  color: #f51826;
  font-size: 1.6rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header .row .info .phone a:hover {
  cursor: pointer;
  color: #1c1a1a;
}
header .row .info .social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
header .row .info .social a {
  width: 2.4rem;
}
header .row .info .social a img {
  width: 100%;
  height: auto;
}
header .row .menu-mobile-button {
  display: none;
}
@media (max-width: 37.5em) {
  header .row .menu-mobile-button {
    display: block;
    width: 25px;
    height: 20px;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}
header .row .menu-mobile-button span {
  width: 100%;
  height: 2px;
  background: #1c1a1a;
  border-radius: 1px;
  margin-bottom: 6px;
  display: block;
}

.menu-mobile {
  padding: 2rem 0 2rem 4rem;
  background: #1c1a1a;
  width: 90%;
  position: absolute;
  left: -100%;
  height: calc(100vh - 10.4rem);
  z-index: 1;
  overflow-y: scroll;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-mobile .menu-category-title a {
  color: #fff;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1.2rem;
  display: block;
}
.menu-mobile .info {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-mobile .info .phone {
  margin: 2rem 0;
}
.menu-mobile .info .phone a {
  text-decoration: none;
  font-weight: 700;
  color: #f51826;
  font-size: 1.6rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-mobile .social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.menu-mobile .social a {
  width: 2.4rem;
}
.menu-mobile .social a img {
  width: 100%;
  height: auto;
}

.right {
  left: auto;
  right: 100%;
}

.toggle-active {
  left: auto;
  right: 0;
}

.company-bg {
  background: url("/img/image-3.jpg") center no-repeat;
  background-size: cover;
  margin: 0;
}
.company-bg .row {
  display: flex;
}
.company-bg .row .left {
  width: calc(60% - 2rem);
  margin-right: 2rem;
}
@media (max-width: 37.5em) {
  .company-bg .row .left {
    width: 100%;
  }
}
.company-bg .row .right {
  width: 40%;
}
@media (max-width: 37.5em) {
  .company-bg .row .right {
    width: 100%;
  }
}
.company-bg .company-text {
  color: #fff;
  padding: 15rem 0 5.5rem 0;
  width: 100%;
  max-width: 68rem;
}
@media (max-width: 37.5em) {
  .company-bg .company-text {
    margin: 0 2rem;
  }
}
.company-bg .company-text .company-header {
  font-size: 5.5rem;
  line-height: normal;
  font-weight: 800;
}
.company-bg .company-text .company-p {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  padding: 5rem 0;
  margin-bottom: 0;
  max-width: 57rem;
}
@media (max-width: 37.5em) {
  .company-bg .company-text .company-p {
    margin-bottom: 2rem;
  }
}
.company-bg .company-text .company-showreel {
  display: flex;
  align-items: center;
  margin-bottom: 15.5rem;
}
.company-bg .company-text .company-showreel p {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
}
.company-bg .company-text .company-showreel .showreel {
  margin: 0 2rem;
}
.company-bg .company-text .company-social {
  display: flex;
  align-items: center;
}
@media (max-width: 37.5em) {
  .company-bg .company-text .company-social {
    justify-content: flex-start;
  }
}
.company-bg .company-text .company-social p {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.8rem;
}
@media (max-width: 37.5em) {
  .company-bg .company-text .company-social p {
    width: 13rem;
  }
}
.company-bg .company-text .company-social .company-social-items {
  display: flex;
  align-items: stretch;
}
.company-bg .company-text .company-social .company-social-items a {
  width: 1.6rem;
  margin: 0.5rem;
}
.company-bg .company-text .company-social .company-social-items a img {
  width: 100%;
  height: auto;
}

hr {
  height: 0.1rem;
  width: 8rem;
  margin: 0 2.5rem;
}

.accent {
  color: #f51826;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.accent:hover {
  color: #1c1a1a;
}

h2 {
  font-size: 4.2rem;
  margin-bottom: 5rem;
}
@media (max-width: 37.5em) {
  h2 {
    text-align: center;
    font-size: 3.5rem;
  }
}

h2::before {
  content: url("/img/red.svg");
  height: 2rem;
  padding-right: 2rem;
}
@media (max-width: 37.5em) {
  h2::before {
    padding-right: 1rem;
  }
}

.services {
  margin: 10.7rem 0 13.4rem;
}
.services .services-items {
  display: flex;
  gap: 2rem;
}
@media (max-width: 37.5em) {
  .services .services-items {
    flex-direction: column;
    align-items: center;
  }
}
.services .services-items .services-item p {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 3rem;
}
.services .services-items .services-item .services-item-img {
  width: 100%;
  max-width: 26.5rem;
}
.services .services-items .services-item img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

.about-us .row {
  display: flex;
  align-items: center;
}
.about-us .left {
  width: 40%;
  position: relative;
}
@media (max-width: 37.5em) {
  .about-us .left {
    display: none;
  }
}
.about-us .left .about-us-img1 {
  padding-left: 1.3rem;
  width: 110%;
  height: auto;
  position: relative;
}
.about-us .left .about-us-img2 {
  position: absolute;
  top: 0rem;
  right: 7rem;
}
.about-us .left .about-us-img3 {
  position: absolute;
  bottom: 1.5rem;
  right: 5rem;
}
.about-us .right {
  width: 60%;
  background-color: #1c1a1b;
}
@media (max-width: 37.5em) {
  .about-us .right {
    width: 100%;
  }
}
.about-us .right .about-us-bg {
  background: url("/img/grey-arr-bg.svg") center no-repeat;
  background-size: 86%;
  background-position-x: 17rem;
}
.about-us .right .about-us-bg .about-us-text {
  color: #fff;
  font-size: 1.8rem;
  padding: 8rem 0rem 13rem 15rem;
  max-width: 70rem;
}
@media (max-width: 37.5em) {
  .about-us .right .about-us-bg .about-us-text {
    padding: 5rem 3rem;
  }
}
.about-us .right .about-us-bg .about-us-text p {
  margin-bottom: 3rem;
  line-height: 3rem;
}

.videos-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/vid1.png") center no-repeat;
  background-size: cover;
}
.videos-bg .videos-header {
  padding-top: 5rem;
  color: #fff;
}
.videos-bg .videos-carousel .focused-slide-heading {
  position: relative;
}
.videos-bg .videos-carousel .center {
  position: absolute;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.videos-bg .videos-carousel .carousel-vid-header {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 7rem;
  background-color: rgba(0, 0, 0, 0.9);
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.videos-bg .videos-carousel .carousel-vid-header p {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  padding-left: 3.8rem;
  margin-bottom: 0;
}

.hosts {
  padding: 12rem 0 10rem;
}
@media (max-width: 37.5em) {
  .hosts {
    padding: 5rem 0;
  }
}
.hosts .row {
  display: flex;
  align-items: center;
  background: url("/img/white-arr-bg.svg") center no-repeat;
  background-size: 50% 55%;
  background-position-x: -10rem;
}
@media (max-width: 37.5em) {
  .hosts .row {
    flex-direction: column;
    gap: 1rem;
  }
}
.hosts .row .left {
  width: 40%;
}
@media (max-width: 37.5em) {
  .hosts .row .left {
    width: 100%;
  }
}
.hosts .row .left h2 {
  margin-bottom: 0;
}
@media (max-width: 37.5em) {
  .hosts .row .left h2 {
    margin-bottom: 1rem;
  }
}
.hosts .row .right {
  width: 60%;
}
@media (max-width: 37.5em) {
  .hosts .row .right {
    width: 100%;
  }
}
.hosts .row .right .hosts-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 37.5em) {
  .hosts .row .right .hosts-items {
    flex-direction: column;
    align-items: center;
  }
}
.hosts .row .right .hosts-items .hosts-item {
  width: 33%;
  max-width: 20.8rem;
}
@media (max-width: 37.5em) {
  .hosts .row .right .hosts-items .hosts-item {
    width: 100%;
  }
}
.hosts .row .right .hosts-items .hosts-item img {
  width: 100%;
  height: auto;
}
.hosts .row .right .hosts-items .hosts-item p {
  margin-top: 2.5rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.client-feedbacks {
  background-color: #1c1a1a;
  padding: 6rem 0;
}
.client-feedbacks .client-feedbacks-header {
  color: white;
  padding-bottom: 4rem;
}
@media (max-width: 37.5em) {
  .client-feedbacks .client-feedbacks-header {
    padding: 3rem 3rem 4rem;
  }
}
@media (max-width: 37.5em) {
  .client-feedbacks .client-feedbacks-header h2 {
    line-height: 3rem;
  }
}
.client-feedbacks .client-feedbacks-bg {
  background: url("/img/grey-arr-bg.svg") right no-repeat;
}
.client-feedbacks .client-feedbacks-bg .feedback-item {
  background-color: white;
  width: 36rem;
  padding: 18.6rem 4rem 2.8rem;
  border-radius: 1rem;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-review {
  font-size: 1.6rem;
  margin-bottom: 6.2rem;
  line-height: 2.2rem;
  text-indent: 0;
  position: relative;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-review::before {
  content: url("/img/quote.png");
  position: absolute;
  top: -10rem;
}
.client-feedbacks .client-feedbacks-bg .feedback-item hr {
  height: 0.1rem;
  width: 80%;
  color: #9A9A9A;
  opacity: 50%;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-author {
  display: flex;
  gap: 2rem;
  padding: 3rem 0rem 0rem 0rem;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-author .author-photo-wrapper {
  width: 30%;
  max-width: 6.4rem;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-author img {
  border-radius: 50%;
  width: 100%;
  height: auto;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-author .feedback-author-description p {
  margin-bottom: 0;
}
.client-feedbacks .client-feedbacks-bg .feedback-item .feedback-item-author .feedback-author-description .feedback-author-name {
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  padding-bottom: 0.7rem;
}

.clients {
  background-color: #fff;
  padding: 6.6rem 0 9.5rem;
}
.clients .clients-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  .clients .clients-items {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.clients .clients-item {
  width: 25%;
  max-width: 21.7rem;
}
@media (max-width: 37.5em) {
  .clients .clients-item {
    width: 100%;
  }
}
.clients .clients-item img {
  width: 100%;
  height: auto;
}

.blog .blog-bg {
  background: url("/img/blog-bg.svg") center no-repeat;
  background-position-y: 4.1rem;
  padding-top: 13rem;
}
@media (max-width: 37.5em) {
  .blog .blog-bg {
    padding-top: 1rem;
  }
}
.blog .blog-bg .blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 37.5em) {
  .blog .blog-bg .blog-header {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
.blog .blog-bg .blog-header h2 {
  margin-bottom: 0;
  padding: 5rem 0;
}
.blog .blog-bg .blog-posts {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem 12rem;
}
@media (max-width: 37.5em) {
  .blog .blog-bg .blog-posts {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
  }
}
.blog .blog-bg .blog-posts .blog-post {
  width: 32%;
  position: relative;
}
@media (max-width: 37.5em) {
  .blog .blog-bg .blog-posts .blog-post {
    width: 100%;
  }
}
.blog .blog-bg .blog-posts .blog-post a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.blog .blog-bg .blog-posts .blog-post img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.blog .blog-bg .blog-posts .blog-post h3 {
  margin-top: 1rem;
  font-size: 1.8rem;
  padding: 1rem 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog .blog-bg .blog-posts .blog-post:hover h3 {
  color: #f51826;
}
.blog .blog-bg .blog-posts .blog-post p {
  line-height: normal;
  letter-spacing: 0.05rem;
}
.blog .blog-bg .blog-posts .blog-post .blog-post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog .blog-bg .blog-posts .blog-post .blog-post-footer a {
  font-weight: bold;
}
.blog .blog-bg .blog-posts .blog-post .blog-post-footer a:hover {
  cursor: pointer;
  color: #f51826;
}
.blog .blog-bg .blog-posts .blog-post .blog-post-footer p {
  color: #9A9A9A;
  margin-bottom: 0;
}

.contacts {
  background-color: #1c1a1a;
  color: #fff;
}
@media (max-width: 37.5em) {
  .contacts {
    padding: 3rem 2rem 4rem;
  }
}
.contacts .contacts-bg {
  background: url("/img/form-bg.svg") center no-repeat;
  background-position-x: 4rem;
}
.contacts .contacts-bg .row {
  display: flex;
  align-items: center;
}
@media (max-width: 37.5em) {
  .contacts .contacts-bg .row {
    flex-direction: column;
    align-items: center;
  }
}
.contacts .contacts-bg .row .left {
  width: calc(60% - 2rem);
  margin-right: 2rem;
}
@media (max-width: 37.5em) {
  .contacts .contacts-bg .row .left {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 37.5em) {
  .contacts .contacts-bg .row .left h2 {
    line-height: 3rem;
  }
}
.contacts .contacts-bg .row .left p {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  max-width: 60rem;
}
@media (max-width: 37.5em) {
  .contacts .contacts-bg .row .left p {
    text-align: center;
  }
}
.contacts .contacts-bg .row .right {
  width: 40%;
}
@media (max-width: 37.5em) {
  .contacts .contacts-bg .row .right {
    width: 100%;
  }
}

.contacts-form {
  margin: 16.6rem 0 16.5rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 37.5em) {
  .contacts-form {
    margin: 0;
  }
}