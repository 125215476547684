.contacts-form-textarea {
    margin: 1rem 0;
    background-color: $grey-input;
    color: $white;
    font-family: $font;
    font-size: 1.8rem;
    border: 1px solid $white;
    border-radius: 1rem;
    padding: 1rem;
    height: 15rem;

    &::placeholder {
        color: $white;
    }

    @include respond(phone) {
        font-size: 1.6rem;
    }
}
