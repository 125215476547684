header  {
    background: $white;

    .row {
        height: 10.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond(phone) {
            padding: 0 2rem;
        }

        .logo {
            width: 25%;
            max-width: 21.7rem;

            // это трюк для адаптивности, ширина картинки 100%, высота - авто, а ширину в единицах задаешь контейнеру выше 0 21.7rem
            img {
                width: 100%;
                height: auto;
            }
        }

        .menu {
            width: 38%;
            display: flex;
            justify-content: space-between;

            @include respond(phone) {
                display: none;
            }

            li {
                font-size: 2rem;

                a {
                    text-decoration: none;
                    color: $dark-grey;

                    // mixin для плавного перехода цвета. По опыту - 0,3 секунды - самый приятный интервал
                    @include transition(.3s);

                    &:hover {
                        cursor: pointer;
                        color: $red;
                    }
                }
            }
        }

        .info {
            width: 30%;
            max-width: 30rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include respond(phone) {
                display: none;
            }

            .phone {
                width: 60%;

                a {
                    text-decoration: none;
                    font-weight: 700;
                    color: $red;
                    font-size: 1.6rem;
                    @include transition(.3s);

                    &:hover {
                        cursor: pointer;
                        color: $dark-grey;
                    }
                }
            }

            .social {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 40%;

                a {
                    width: 2.4rem;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .menu-mobile-button {
            display: none;

            @include respond(phone) {
                display: block;
                width: 25px;
                height: 20px;
                margin-right: 2rem;
                margin-left: 2rem;
            }

            span {
            width: 100%;
            height: 2px;
            background: $dark-grey;
            border-radius: 1px;
            margin-bottom: 6px;
            display: block;
            }
        }
    }
}

.menu-mobile {
    padding: 2rem 0 2rem 4rem;
    background: $dark-grey;
    width: 90%;
    position: absolute;
    left: -100%;
    height: calc(100vh - 10.4rem);
    z-index: 1;
    overflow-y: scroll;
    @include transition(.3s);

    .menu-category-title {
        a {
        color: $white;
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 1.2rem;
        display: block;
        }
    }

    .info {
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .phone {
            margin: 2rem 0;

            a {
                text-decoration: none;
                font-weight: 700;
                color: $red;
                font-size: 1.6rem;
                @include transition(.3s);
            }
        }
    }

    .social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;

        a {
            width: 2.4rem;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.right {
    left: auto;
    right: 100%;
}

.toggle-active {
    left: auto;
    right: 0;
}



